.PenaltyOvertimeDetails-body>p{
  font-size: 20px;
  padding: 20px;
} 
.PenaltyOvertimeDetail-card{
    background-color: #fff;
    width: 95%;
    margin: auto;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.PenaltyOvertimeDetail-tab-List{
    display: flex;
    gap: 10px;
    align-items: center;
    
}
.PenaltyOvertimeDetail-tab-List>p{
    padding: 10px;
    font-size: 18px;
    cursor: pointer;
    
}
.PenaltyOvertimeDetail-tab-item{
    background-color: #fff;
    width: 95%;
    margin: auto;
    padding: 20px;
}
.EarlyLeavePolicy-radio{
    border: 1px solid rgb(147, 147, 147);
    width: 500px;
    padding: 8px;
    border-radius: 7px;
}
#Policy-radio-label{
    margin-left: 10px;
}