.AdminNavBare {
  display: flex;
  align-items: center;
  padding: 15px;
  position: sticky;
  top: 0;
  gap: 10px;
  background-color: black;
}
.AdminNavBare-left {
  display: flex;
  align-items: center;
  gap: 30px;
  width: 130px;
  height: 30px;
}
.AdminNavBare-left > img {
  width: 100%;
  height: 70px;
  margin-top: 20px;
}

.AdminNavBare-Right {
  width: 100%;
}

.AdminNavBare-Right > ul {
  display: flex;
  align-items: center;
  gap: 25px;
  justify-content: space-between;
}

.nav_link {
  display: flex;
  align-items: center;
  gap: 7px;
  font-size: 13px;
  padding: 5px;
  font-weight: 500;
  border-radius: 10px;
  color: #ffff;
  cursor: pointer;
}
.nav_link{
  display: block;
}