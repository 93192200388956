.LadingPageCardTwo{
    min-height: 91.2vh;
    /* border: 2px solid red; */
}
.LadingPageCardTwo-container{
    display: flex;
    justify-content: center;
    margin: 50px auto;
    flex-wrap: wrap;
    gap: 30px;
    
}
.LadingPageCardTwo-container-left {
   margin-top: 50px;
   width: 700px;
}
.LadingPageCardTwo-container-left h4{
    font-size: 20px;
   
}
.LadingPageCardTwo-container-left p,.LadingPageCardTwo-container-right p {
    font-size: 20px;
    font-weight: 300;
    margin-top: 10px;
}
.LadingPageCardTwo-container-right {
    width: 700px;
    text-align: center;
    height: fit-content;
    margin-top: 50px;
}
.LadingPageCardTwo-container-right h4 {
    font-weight: 400;
    font-size: 24px;
}
.LadingPageCardTwo-container-center {
     width: 300px;
     
}

.card-one,.cards>div{
    border: 1px solid rgb(123, 123, 123);
    width: 150px;
    height: 150px;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    text-align: center;
    
}
.cards {
    display: flex;
    gap: 20px;
    margin-top: 25px;
}
.card-one >img,.cards>div>img{
     width: 20%;
} 
.card-one p,.cards>div>p{
    
    font-size: 22px;
    font-weight: 500;
}
.card-one  label,.cards>div>label{
    font-size: 22px;
    font-weight: 300;
}
.flowchart-img{
    display: flex;
}
.flowchart-img >img{
   max-width: 750px;
   min-width: 50%;
   margin:30px auto;
}

