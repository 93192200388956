.LeaveBalanceDetails {
  /* border: 1px solid red; */
}
.LeaveBalanceDetails-body {
  /* border: 1px solid red; */
}
.LeaveBalanceDetails-body > p {
  padding: 10px;
  font-size: 20px;
}
.LeaveBalanceDetails-card-items {
  background-color: #fff;
  width: 95%;
  margin: auto;
  border-radius: 10px;
}
.LeaveBalanceDetails-tap {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}
/* .LeaveBalanceDetails-tap > button {
  margin-right: 10px;
  font-size: 20px;
  padding: 5px;
  background-color: transparent;
  border: 2px solid rgba(37, 141, 233, 0.369);
  color: rgba(37, 142, 233, 0.898);
} */
.LeaveBalanceDetails-tap-item {
  display: flex;
  gap: 10px;
  align-items: center;
}

.LeaveBalanceDetails-tap-item > P {
  padding: 10px;

  margin-left: 10px;
  font-size: 20px;
}
.LeaveBalanceDetails-tab-item {
  padding: 20px;
}
.LeavePolicy-card {
  text-align: center;
}
.LeavePolicy-radio-btn {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 10px;
}
.LeavePolicy-radio-btn > label > samp {
  margin-left: 10px;
}
.LeavePolicy > p {
  margin-top: 20px;
  font-size: 20px;
}
.LeaveBalance-card {
  display: flex;
  /* padding: 10px; */
  font-size: 20px;
  /* background-color: rgba(148, 200, 242, 0.222); */
  /* margin-top: 10px; */
}
/* .LeaveBalance-card > p {
  flex: 1;
} */
.LeaveBalance-input {
  display: flex;
  padding: 20px 10px;
  font-size: 20px;
  margin-top: 10px;
  background-color: rgba(128, 128, 128, 0.101);
}
.LeaveBalance-input > p {
  flex: 1;
}
.LeaveBalance-input > p > input {
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.103);
  outline: none;
}
.LeaveBalance-input > p > label {
  margin-left: 10px;
}

.LeaveRequests-btn {
  display: flex;
}


.LeaveRequests-input {
  margin: 20px 0;
}

#LeaveRequests-input {
  border: 1px solid rgb(199, 199, 199);
  padding: 10px;
  margin-left: 10px;
  font-size: 18px;
  width: 200px;
}

.LeavePolicy .body-content {
  margin: 30px auto;
  width: 70%;
}

.LeaveBalance .body-content {
    margin: 30px auto;
    width: 50%;
  }

.monthly-content-body {
  border: 1px solid rgb(228, 227, 227);
  border-radius: 7px;
  margin-bottom: 15px;
  padding: 20px;
}
.monthly-content-body:last-child {
  margin-bottom: 0px;
}

.monthly-content-body h4 {
  font-size: 18px;
  font-weight: 500;
}

.monthly-content-body .form-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.monthly-content-body .form-group label {
  white-space: nowrap;
}

.monthly-content-body .form-group input {
  width: 200px;
}

.monthly-content-body .form-group .input-label {
    width: 70%;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
}
