.LadingPageCard-three{
    min-height: 91.2vh;
    background: linear-gradient(to bottom, #187ABC, #69B9EE);
    color: #ffff;
    padding: 20px 0px;
}
.LadingPageCard-three-heading{
    
    text-align: center;
    width: 50%;
    margin: auto;
}
.LadingPageCard-three-heading h2{
    font-weight: 400;
    font-size: 30px;
   
}
.LadingPageCard-three-heading p {
    font-weight: 300;
    font-size: 20px;
    margin-top: 20px;
}
.LadingPageCard-three-body{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 20px;
    gap: 30px;
    
}
.three-body-left{
 flex: 1;
 /* border: 1px solid red; */

}
.cards-LadingPageCard{
 
display: flex;
flex-wrap: wrap;
gap: 15px;
width: 600px;
margin: auto;
position: relative;
z-index: 1;
}
.box-card{
    border: 10px solid #3282F3  ;
    border-radius: 60px;
    max-width: 240px;
    height: 240px;
    text-align: center;
    background-color: #65A5E6;
    padding: 15px;
    font-size: 13.4px;
}

#icon-a{
    background-color: #076BAE;
    padding: 10px;
    position: absolute;
    left: 38%;
    top: 45%;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

.left-top{
    border-top-left-radius: 40%;
}
.right-top {
    border-top-right-radius: 40%;
}
.right-bottom{
    border-bottom-right-radius: 40%;
}
.three-body-right {
    flex: 1;
   
}

.three-body-right>form{
  width: 500px;
  margin: auto;
  background: linear-gradient(to bottom, #0B9BFC, #0B9BFC);
  border-radius: 20px;
  padding: 20px;

}
.form_heading{
    text-align: center;
 
}
.input-tag{
    width: 100%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
    row-gap: 20px;
   
}
.input-tag>input{
  /* width: 90%; */
  margin: auto;
  padding: 15px;
  border-radius: 10px;
  background-color: #3FBEFF;
  outline: none;
  border: none;
 color: #ffff;
font-size: 17px;
}
.input-tag>input::placeholder {
    color: #ffff;
    font-size: 20px;
   
    
}
#name{
    width: 95%;
}
#companyName{
    width: 35%;
}
#empoly{
    width: 50%;
}
#businessName{
    width: 95%;
}
#india{
width: 35%;
}
#phoneNumber{
    width: 50%;
}
form >p{
    font-size: 17spx;
    text-align: center;
    margin-top: 10px;
}
.form-btn{
   
    display: flex;
    margin-top: 40px;
    padding-bottom: 40px;
}

.form_heading>h3{
    font-weight: 400;
    font-size: 20px;
}
.form_heading>p{
    font-weight: 400;
   font-size: 18px;
   margin-top: 10px;
}
.form-btn>button{
    margin: auto;
    width: 55%;
    padding: 20px;
    font-size: 22px;
    background-color: #115D8C;
    color: #fff;
    border-radius: 10px;
    border: none;
}
