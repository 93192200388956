.p_btn {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #3c3d3f;
  background-color: #ffffff;
  border: 1px solid #d5d9d9;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 7px;
  width: fit-content;
  /* min-width: 160px; */
  text-decoration: none;
}
