.CompanyDetails{
    width: 90%;
    margin: 50px auto;
    background-color: #fff;
    border-radius: 10px;
    
}
.CompanyDetails-head{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border-bottom: 1px solid rgb(210, 210, 210);
}
.CompanyDetails-head>p {
    font-size: 20px;
    font-weight: 400;
}

.CompanyDetails-body{
    
     margin-top: 20px;
     padding-bottom: 40px   ;
}
.CompanyDetails-logo{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
}
.CompanyDetails-logo>p{
    font-size: 20px;
    font-weight: 400;
}
.CompanyDetails-logo>p>span {
    font-size: 20px;
    font-weight: bold;
}

.CompanyDetails-body>form{
    
    width: fit-content;
    margin:20px auto;

}