.Branches-body {
  background-color: #fff;
  padding: 20px;
}

.Branches-table {
  /* border: 1px solid red; */
  margin-top: 10px;
  width: 80%;
  margin: auto;
  min-height: 40vh;
}
.Branches-btn {
  /* border: 1px solid red; */
  padding: 20px;
  display: flex;
  justify-content: flex-end;
}
.Branches-btn > button {
  font-size: 16px;
  border: none;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.Branches-table-button {
  display: flex;
  align-items: center;
  gap: 20px;
}

.Branches-table-Avatar {
  display: flex;
  gap: 20px;
  align-items: center;
}
.Branches-table-info > p {
  font-size: 19px;
  font-weight: 500;
}
.Branches-table-info > label {
  font-size: 18px;
  font-weight: 400;
  display: inline-block;
  margin-top: 10px;
}

.emp-tbl .sc-blHHSb.dknajr.rdt_Table,
.sc-dprtRQ.htmdXE.rdt_TableBody {
  display: flex;
  justify-content: space-between;
}

/* .rdt_TableHeadRow .rdt_TableCol, .ldt_TableHeadRow .ldt_TableCol
.rdt_TableCol
{
  font-size: 16px;
  z-index: 0;
  background-color: red
} */
