.center-menu {
  position: fixed;
  top: 150px;
  left: 50%;
  width: 60%;
  transform: translate(-50%);
  padding: 10px;
  background-color: #fff;
  z-index: 1;
}
