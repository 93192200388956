.superAdminPanelLayOut {
    height: 100vh;
    overflow: auto;
    background-color: #F2F0F0;

}

.superAdminPanelLayOut-body {
    display: flex;
}

/* .AdminPanelLayOut-body-left{
    flex: 1.2;
    border: 1px  solid red;
} */
.superAdminPanelLayOut-body-right {
    height: 91vh;
    overflow: auto;
    width: 100%;



}