.SettingBody {
  height: 91.1vh;
  overflow: auto;
  /* border: 2px solid blue; */
}
.SettingBody-head {
  padding: 10px;
  font-size: 20px;
  background-color: #fff;
  display: flex;
  align-items: center;
  gap: 20px;
  position: sticky;
  top: 1px;
  z-index: 1;
}
.SettingBody-head  a {
  color: gray;
  font-size: 16px
}
.SettingBody-card {
  /* background-color: #fff;
     width: 90%;
     margin: auto;
     margin-top: 40px;
     padding: 10px;
     border-radius: 10px; */
  /* height: 86vh;
      padding-bottom: 100px; */

  width: 90%;
  margin: auto;
}

/* .setting-cards{
background-color: #fff;
    width: 90%;
    margin: auto;
    margin-top: 40px;
    padding: 10px;
    border-radius: 10px;
} */
.setting-cards-title > p {
  padding: 10px;
  font-weight: 500;
  font-size: 24px;
}
.setting-cards-Links {
  border-bottom: 1px solid rgb(213, 213, 213);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-top: 10px;
  color: black;
  text-decoration: none;
}
.link-info {
  display: flex;
  gap: 15px;
  align-items: center;
}

.link-info > p {
  font-weight: 400;
  padding: 10px;
}
.link-info-title > p {
  font-size: 20px;
  font-weight: 500;
  color: black;
}
.setting-cards-Links {
  text-decoration: none;
  /* This removes the underline from the Link */
}
.link-info-title > label {
  font-size: 18px;
  margin-top: 10px;
  font-weight: 300;
  display: inline-block;
}
.setting-cards-Links:hover {
  background-color: rgba(154, 218, 250, 0.22);
}

.SalarySetting-checkbox {
  /* border: 1px solid red ; */
  display: flex;
  gap: 20px;
}
.SalarySetting-checkbox-info > p {
  font-size: 20px;
  font-weight: 500;
}
.link-info > form {
  display: flex;
  gap: 15px;
  align-items: center;
  padding: 10px;
}
.AttendanceCycles > p {
  font-size: 20px;
  font-weight: 500;
}
/* .AttendanceCycles-input{
     display: flex;
     gap: 20px;
     align-items: center;
     margin-top: 20px;
} */
.AttendanceCycles-input > select {
  height: 50px;
  width: 50px;
  text-align: center;
  font-size: 20px;
  border: 1px solid #d5d9d9;
  border-radius: 7px;
  webkit-appearance: none;
  -moz-appearance: none;
  background-color: transparent;
  /* background-image: url("https://icons.veryicon.com/png/o/miscellaneous/template-4/drop-down-13.png");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px; */
  border: 1px solid #dfdfdf;
}
.AttendanceCycles-input > p {
  font-size: 20px;
}

/* SettingCard */
.SettingTitle-body {
  margin-top: 20px;
  width: 95%;
  margin: 30px auto;
}
.SettingTitle-body > p {
  font-size: 22px;
  font-weight: 500;
  text-align: left;
}
.Setting-body-content {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  margin-top: 20px;
}

.SettingCard {
  background-color: #ffffff;
  border-radius: 10px;
  width: 369px;
  position: relative;
}

.SettingCard-item {
  display: flex;
  padding: 20px;
  justify-content: space-between;
  gap: 20px;
  position: relative;
}
.SettingCard-icon {
  /* border: 1px solid red; */
}
.SettingCard-body {
  flex: 1;
}
.SettingCard-body {
  position: relative;
}

.SettingCard-body > p {
  font-weight: 500;
}

.SettingCard-body > label {
  display: block;
  font-weight: 300;
  margin-top: 5px;
}

.SettingCard-Button {
  display: flex;
  justify-content: end;
  position: absolute;
  /* top: 100%; */
  right: -5px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  bottom: -7px;
  z-index: 0;
  background-color: #f2f0f0;
}

.SettingCard-Button::before {
  position: absolute;
  content: "";
  bottom: 0.375rem;
  left: -1.2rem;
  background: transparent;
  width: 1.25rem;
  height: 1.25rem;
  border-bottom-right-radius: 1.25rem;
  box-shadow: 0.313rem 0.313rem 0 0.313rem #f2f0f0;
}

.SettingCard-Button::after {
  position: absolute;
  content: "";
  top: -1.25rem;
  right: 0px;
  background: transparent;
  width: 1.25rem;
  height: 1.25rem;
  border-bottom-right-radius: 1.25rem;
  box-shadow: 0.313rem 0.313rem 0 0.313rem #f2f0f0;
  z-index: -1;
}

.SettingCard-Button > a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 37px;
  width: 37px;
  border-radius: 50%;
  right: 5px;
  bottom: -6px;
  border: none;
  color: #262626;
  background-color: #ffffff;
  text-decoration: none;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.btn-arrow {
  transition: all 0.4s;
}

.SettingCard-Button > a:hover .btn-arrow {
  animation: moveAnim 0.4s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

@keyframes moveAnim {
  50% {
    transform: translateX(6px);
  }
}

.setting-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 90%;
  margin: auto;
  margin-top: 40px;
  padding: 10px;
  border-radius: 10px;
}

.item {
  border: 2px solid red;
}

.SalarySetting {
  width: 48.5%;
  background-color: #fff;
  border-radius: 7px;
  padding: 20px;
}
.SalarySetting-input {
  display: flex;
  gap: 10px;
}

.SalarySetting-input > label {
  font-size: 15px;
  flex: 1;
}

.SalarySetting-input > select {
  flex: 6;
  border: 1px solid #d5d9d9;
  border-radius: 7px;
  background-color: white;
  height: 38px;
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */

  vertical-align: middle;
  background: transparent;
  vertical-align: middle;
  background: transparent;
  /* -webkit-appearance: none;
  appearance: none; */
  padding-left: 10px;

  /* background-image: url("https://icons.veryicon.com/png/o/miscellaneous/template-4/drop-down-13.png");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px; */
}
.SalarySetting-input > select:focus {
  outline: none;
}
.SalarySetting-input > select:after {
  content: "\2304";
  font-size: 30px;
  /* line-height: 23px; */
  padding-right: 20px;
}

.SalarySetting-input > button {
  background-color: #fff;
  border: 1px solid #d5d9d9;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  box-sizing: border-box;
  color: #0f1111;
  cursor: pointer;
  display: inline-block;
  font-family: "Amazon Ember", sans-serif;
  font-size: 13px;
  line-height: 29px;
  padding: 0 10px 0 11px;
  position: relative;
  text-align: center;
  text-decoration: none;
  /* user-select: none;
  -webkit-user-select: none; */
  /* touch-action: manipulation; */
  vertical-align: middle;
  width: 100px;
}

.button-13:hover {
  background-color: #f7fafa;
}

.button-13:focus {
  border-color: #008296;
  box-shadow: rgba(213, 217, 217, 0.5) 0 2px 5px 0;
  outline: 0;
}

.AttendanceCycles-input {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
  border: none;
  border-radius: 5px;
  /* border: 1px solid rgb(129, 127, 127); */
}

.sub-btn {
  border: none;
  border-radius: 5px;
  /* border: 1px solid rgb(129, 127, 127); */
}

.salary_settings{
  padding: 30px;
}

.attendance-settings-left{
}
.attendance-settings-left .inner-div{
  padding: 10px;
  background-color: #fff;
}
.right-left-align{
  text-align: right;
}

.option_dropdown {
  width: 100%;
  border: 1px solid lightgray;
  border-radius: 7px;
  padding: 10px;
  outline: none;
  background-color: #fff;
}


@media screen and (max-width : 768px ) {
  .SalarySetting {
    width: 100%;
    background-color: #fff;
    border-radius: 7px;
    padding: 20px;
  }
}

