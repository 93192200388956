.AddShift-radio {
  border: 1px solid rgb(231, 229, 229);
  /* display: flex; */
  width: 48%;
  align-items: center;
  gap: 20px;
  padding: 5px 8px;
  border-radius: 8px;
}
.AddShift-radio > input {
  width: 20px;
  height: 20px;
}
.AddShift-input {
  border: none;
}
.AddShift-input > input {
  width: 40px;
  height: 40px;
}
