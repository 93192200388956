.superAdminNavBare {
    display: flex;
    align-items: center;
    padding: 15px;
    position: sticky;
    top: 0;
    gap: 10px;
    background-color: black;
}

.superAdminNavBare-left {
    display: flex;
    align-items: center;
    gap: 30px;
    width: 130px;
    height: 30px;


}

.superAdminNavBare-left>img {
    width: 100%;
    height: 70px;
    margin-top: 20px;

}

.superAdminNavBare-Right {
    width: 100%;
    overflow: scroll;


}

.superAdminNavBare-Right>ul {
    display: flex;
    align-items: center;
    gap: 25px;
    justify-content: space-between;
    width: fit-content;
    



}

#superAdmin_nav_link {
    display: flex;
    align-items: center;
    gap: 7px;
    font-size: 13px;
    padding: 5px;
    font-weight: 500;
    border-radius: 10px;
    color: #ffff;
    cursor: pointer;
    white-space: nowrap;
    min-width: fit-content;





}