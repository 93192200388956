.Holiday-table-button>button{
    border: 2px solid green;
    padding: 7px 15px;
    background-color: transparent;
    color: green;
    font-size: 18px;


}
.Holiday-table-head{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.Holiday-table-btn{
    display: flex;
    align-items: center;
    gap: 20px;
}
.Holiday-table-btn>button{
        color: black;
        font-size: 15px;
        border-radius:7px;
        border: none;
        padding: 10px 20px;
        display: flex;
        align-items: center;
        gap: 10px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
#Add-Holiday-btn{
    background-color: transparent;
    color:rgb(63, 124, 237);
    border: 2px solid rgb(63, 124, 237);;
}

.Holiday-table-title{
    display: flex;
    align-items: center;
    gap: 20px;
}