
nav{
    padding: 10px;
    background-color: #fff;
    position: sticky;
    top: 0;
    z-index: 2;
}
.nav-container{
   
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 75%;
    margin: auto;
}
.logo{
   
}
.logo img{
    width: 150px;
}
.naveList{
   
    display: flex;
    align-items: center;
    gap: 40px;
}
.naveList li{
    display: inline-block;
    font-size: 20px;
    font-weight: 500;
}
#last-li{
    font-size: 18px;
     color: #FFFFFF;
    background: #019EFF;
    border-radius: 15px;
    text-align: center;
    cursor: pointer;
    padding: 15px 20px;
}