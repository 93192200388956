.cards-body{
    background-color: #fff; 
    width: 200px;
    min-width: 200px;
     padding: 20px;
    border-radius: 15px;
}
.cards-body>p{
    margin-top: 15px;
    font-weight: 500;
}
.cards-body>h2{
    margin-top: 4px;
}
.cards-body>h2>samp{
    font-size: 19px;
}


/* WishesCards */
.WishesCards-body{
    background-color: #fff;
    width: 400px;
    min-width: 300px;
    border-radius: 15px;
    padding: 25px;
}
.WishesCards-body>p{
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    /* padding: 20px; */

}

.WishesCards-UserInfo{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    
}
.WishesCards-userName{
     display: flex;
    align-items: center;
    gap: 20px;
}
.WishesCards-userName>p{
    font-weight: 500;

}
.WishesCards-userName>p>label{
    display:block;
    font-weight: 300;
}
.WishesCards-UserInfo>button{
    background-color: black;
    border: none;
    border-radius: 20px;
    color: #fff;
    font-weight: 500;
    padding: 7px 20px;
}