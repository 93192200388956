.UserPermission-body{
    background-color: #fff;
    padding: 20px;
    display: flex;
    justify-content:center;
   
    
}
.UserPermission-card{
/* border: 1px solid red; */
width: 50%;
}
.FormControl{
    /* border: 1px solid red; */
    width: 100%;
}
.radio{
    border: 1px solid rgb(14, 13, 13);
    padding: 10px;
    margin-top: 5px;
    border-radius: 5px;
    
}