.AddShift-radio {
  border: 1px solid rgb(231, 229, 229);
  /* display: flex; */
  width: 48%;
  align-items: center;
  gap: 20px;
  padding: 5px 8px;
  border-radius: 8px;
}
.AddShift-radio > input {
  width: 20px;
  height: 20px;
}
.AddShift-input {
  border: none;
}
.AddShift-input > input {
  width: 40px;
  height: 40px;
}

.shift-type-container {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 20px;
}

.shift-type-container .days-container {
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 10px;
}
.shift-type-container .days-container > .title {
  font-size: 16px;
  font-weight: bold;
}
.shift-type-container .days-container .button-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
}
.shift-type-container .days-container .button-container button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: transparent;
  border: 1px solid lightgray;
}
.shift-type-container .days-container .button-container button.active {
  background-color: skyblue;
  color: #fff;
  border: 1px solid skyblue;
}
/* ============================================ */
.shift-type-container .time-gross-container {
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 10px;
}

.shift-type-container .time-gross-container > .title{
  font-size: 16px;
  font-weight: bold;
}

.shift-type-container .time-gross-container .input-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
}

.shift-type-container .time-gross-container .input-container .time-input {
  width: 150px;
  outline: none;
}

/* ============================================ */
.shift-type-container .break-duration-container {
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 10px;
}

.shift-type-container .break-duration-container > .title{
  font-size: 16px;
  font-weight: bold;
}

.shift-type-container .break-duration-container .input-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
}

.shift-type-container .break-duration-container .input-container .time-input {
  width: 150px;
  outline: none;
}
/* ========================== */
.shift-type-container .week-off-container {
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 25px;
}

.shift-type-container .week-off-container > .title{
  font-size: 16px;
  font-weight: bold;
}

.week-off {
  position: relative;
  top: -10px
}
/* ======================================= */
.remove-field {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.remove-field .icon{
  position: relative;
  top: 15px;
  font-size: 25px;
  font-weight: bold;
  color: red;
  cursor: pointer;
}
.search-employee {
  padding : 10px;
  border: 1px solid lightgray;
  border-radius: 5px;
  outline: none;
}

.tbl-row-list li {
  list-style: none ;
  line-height: 20px;
}

.colored-checkbox{
  width: 20px;
  height: 20px;
  appearance: none;
  background-color: lightgray;
  border: 2px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

.colored-checkbox:checked {
  background-color: #0a0a0a;
  border-color: #0a0a0a;
}