.table {
    width: 100%;
    background-color: #fff;


}

.table-heading {
    position: sticky;
    top: -20px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
   }


.table-heading>p {
    padding: 10px 0px 0px 10px;
    font-size: 18px;

}



.search-input {
    width: 100%;
}


table {
    border-collapse: collapse;
    width: 100%;
}


tr:hover {
    background-color: #f9f9f9;

}

th {
    position: sticky;
    top: 0%;
    height: 50px;
    color: black;
    background-color: #fff;
    
}

th{
        text-align: left;
        padding: 10px 16px;
        border-bottom: 1px solid rgb(171, 171, 171);
        font-size: 17px;
        font-weight: bold;

    }
td {
    text-align: left;
    padding: 10px 16px;
    border-bottom: 1px solid rgb(171, 171, 171);
    font-size: 17px;
    font-weight: 500;
    
}

.row-page {
    background-color: white;
    width: 98%;
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
    font-size: 15px;
    font-weight: 400;
    position: sticky;
    bottom: 0.9px;
    /* background-color: #6a6a6a; */
    color: rgb(120, 120, 120);
    /* border-radius: 10px; */
    padding: 10px;
}

.row-page>div {
    padding: 10px;

}

.row-page>div>label {
    padding: 10px;
    margin-right: 40px;
}

.crount_page button {
    background: none;
    border: none;
    color: #111010;
}

.crount_page button:active {
    /* background-color: #151414; */
}

#tableInput {
    outline: none;
    border: none;
    width: 100%;
    padding: 5px;
    background-color: transparent;
    border-bottom: 1px solid rgb(2, 2, 2);

}

.table_btn {
    display: flex;
}