.Departments-btn{
    display: flex;
    justify-content: space-between;
}
/* .Departments-btn>button{
background-color: rgb(63, 124, 237);
    color: aliceblue;
    font-size: 20px;
    border-radius: 10px;
    border: none;
    padding: 10px 20px;
    display: flex;
     align-items: center;
     gap: 10px;
    
} */
.Department-table-button{
    
    display: flex;
    gap: 30px;
    align-items: center;
    justify-content: flex-end;
}
.Department-table-button>button {
    border: 1px solid rgb(191, 191, 191);
   padding: 10px 20px;
   font-size: 15px;
   background-color: #fdfcfccf;
   font-weight: 500;
  
}

.pop-from{
    /* border: 1px solid red; */
    position: absolute;
    width: 100%;
    z-index: 3;
    left: 0px;
    height: 100vh;
    top: 0;
    display: flex;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.329);
    align-items: center;
}
.DepartmentsForm{
    background-color: #fff;
    width: fit-content;
    padding: 20px;
    height: fit-content;
    border-radius: 10px;
}
.DepartmentsForm>form {
    width: 500px;
}
.pop-Form-btn{
    display: flex;
    /* padding: 20px; */
    justify-content: flex-end;
    gap: 20px;
    margin-top: 20px;
}
.pop-Form-btn >button{
   padding: 10px 20px;
   font-size: 18px;
background-color: rgb(63, 124, 237);
    color: aliceblue;
    border: none;
    border-radius: 8px;
    cursor: pointer;
}
.DepartmentsForm>h3{
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    
}