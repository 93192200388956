.sticky-stepper {
  position: sticky;
  top: 0;
  padding: 10px 0;
  background-color: #fff;
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
  z-index: 2;
}
.stepper-container {
  padding: 50px 100px 100px 100px;
}
.stepper-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.small-description {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  border: 1px solid lightgray;
  border-radius: 7px;
  padding: 20px;
  width: 80%;
  margin: 30px 0 60px 0;
}

.small-description .profile {
  display: flex;
  align-items: center;
  gap: 20px;
}

.small-description .profile img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.small-description .profile div h1 {
  font-size: 18px;
}
.small-description .profile div span {
  font-size: 14px;
}

.small-description .small-detail {
  display: flex;
  flex-direction: column;
  /* align-items: start; */
  justify-content: start;
  gap: 5px;
}
/* BODY============================================= */
.stepper-body {
  position: relative;
  top: 20px;
  width: 100%;
  padding: 20.9px;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
  border-radius: 7px;
}

.stepper-body .selfie-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.stepper-body .selfie-image img{
  width: 100px;
  height: 100px;
  border-radius: 50%;
  cursor: pointer;
}

.stepper-body .heading {
  font-size: 16px;
  border-bottom: 1px solid lightgray;
  margin-bottom: 15px;
  padding-bottom: 10px;
  text-align: left;
}
/* FOOTER=========================================== */
.stepper-footer {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
  width: 100%;
  height: 40px;
  margin-top: 50px;
}

.stepper-footer button {
  padding: 0 20px;
  width: 100px;
  height: 35px;
}
.file_input {
  border: 1px dotted lightgray;
  width: 300px;
  height: 100px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: gray;
  font-size: 18px;
}
.file_input:hover {
  background-color: #eeeded96;
}

.welcom-body {
  width: 100%;
  height: 100vh;
  font-family: Arial, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #6e8efb, #a777e3);
  color: #fff;
}

/* Container for central content */
.welcom-body > .container {
  text-align: center;
  padding: 2rem;
  max-width: 600px;
  width: 90%;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
}

.welcom-body > .container h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  font-weight: bold;
}

.welcom-body > .container p {
  font-size: 1.1rem;
  line-height: 1.5;
  margin-bottom: 2rem;
}

/* Button styles */
.welcom-body > .container .button {
  display: inline-block;
  padding: 0.8rem 2rem;
  font-size: 1rem;
  color: #fff;
  background-color: #ff8c00;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
  transition: background 0.3s ease;
}

.welcom-body > .container .button:hover {
  background-color: #ff7b00;
}

/* Media query for smaller screens */
@media (max-width: 600px) {
  .welcom-body > .container h1 {
    font-size: 2rem;
  }
  .welcom-body > .container p {
    font-size: 1rem;
  }
  .welcom-body > .container .button {
    font-size: 0.9rem;
    padding: 0.6rem 1.5rem;
  }
}

/* CUSTOM STEPPER STYLES */

.page_loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


/* Custom styles for react-datepicker */
.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Style for the calendar */
.react-datepicker__header {
  background-color: #007bff;
  color: white;
}


.selfie-edit-icon{
  position: absolute;
  top:-10px;
  right: -10px;
}