.LadingPageCardFour{
    min-height: 80.2vh;
}
.heading{
    text-align: center;
}
.heading>h1{
    font-size: 40px;
}
.heading>p {
    font-size: 20px;
    margin-top: 10px;
    font-weight: 300;
}
.LadingPageCardFour-body{
    display: flex;
    justify-content: space-around;
     width: 90%;
    margin:60px auto;
    flex-wrap: wrap;
    gap: 20px;
}
.LadingPageCardFour-box {
    
    background-color: #B6D1FF;
    text-align: center;
    width: 250px;
    height: 250px;
    border-radius: 20px;
    padding: 20px;
box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    
}
.LadingPageCardFour-box-img{
    background-color: #75A7FA;
    width: fit-content;
    margin: auto;
    padding: 15px;
    border-radius: 10px 20px 10px 10px;
    width: 50px;
    height: 50px;
   
   
}
.LadingPageCardFour-box-img>img{
    width: 90%;
}
.LadingPageCardFour-box>h1{
    font-size: 24px;
    margin-top: 10px;
}
.LadingPageCardFour-box>p{
    font-weight: 300;
     margin-top: 10px;
    font-size: 14.3px;
}


