.more-setting-container {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 5px;
}

.more-setting-container .main-tab{
    position: sticky;
    top: 0px;
    height: 91vh;
    width: 400px;
    background-color: #fff;
    /* padding: 10px; */
    display: flex;
    flex-direction: column;
    /* gap: 5px; */
    overflow-y: scroll;
}

.more-setting-container  .main-tab button{
    border: none;
    text-align: left;
    height: 40px;
    padding: 0 10px;
    background-color: #ffffff;
    border-bottom: 1px solid rgb(228, 226, 226);
}


.more-setting-container  .main-tab button:hover{
    background-color: #9b9999;
    color: #ffffff;
}

.more-setting-container  .main-tab button.active{
    background-color: #9b9999;
    color: #ffffff;
}

.more-setting-container  .main-tab-body{    
    padding-bottom: 30px;
    border-radius: 7PX;
    height: 91vh;
    width: 100%;
}
.more-setting-title {
    background-color: #fff;
    padding: 20px;
    margin-bottom: 5px;
    border-radius: 7PX;
}
.more-setting-form-container{
    background-color: #fff;
    padding: 20px;
    border-radius: 7PX;
    /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
}
.more-setting-form-container .flex-form{
    display: flex;
    align-items: center;
    border: 1px solid lightgray;
    border-radius: 7px;
    padding: 10px;
}

/* SHIFT SETTING CSS */
.AttendanceDetails{
    /* border: 1px solid red; */
    
}
.AttendanceDetails>p {
  padding: 20px;
  font-size: 18px;
}
.AttendanceDetails-heading{
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background-color: #fff;
    align-items: center;
    width: 97%;
    margin: auto;
}
.AttendanceDetails-tap{
    display: flex;
    gap: 10px;
    font-size: 19px;
}
.AttendanceDetails-tap>p{
    padding: 10px;
    cursor: pointer;
}
.AttendanceDetails-body{
    /* padding: 10px; */
    background-color: #fff;
    width: 97%;
    margin:0 auto;
}

.AttendanceRules{
    margin: 20px;
}
.AttendanceRules-tap{
    border: 1px solid rgb(190, 190, 190);
border-bottom: none;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
.AttendanceRules-tap:first-child {
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}
.AttendanceRules-tap:last-child {
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    border-bottom: 1px solid rgb(190, 190, 190);;
}
.AttendanceRules-tap>p{
    display: flex;
    font-weight: 500;
    gap: 10px;
    font-size: 18px;
}

.AttendanceRules-tap>button{
    border: 1px solid rgb(180, 179, 179);
    background-color:rgba(229, 229, 229, 0.159);
    font-size: 16px;
    padding: 7px;
}
.AttendanceRules-tap:hover{
    background-color: rgba(157, 211, 242, 0.084);
}
.MarkAttendanceform-radio{
    font-weight: 500;
    border: 1px solid rgb(156, 156, 156);
    border-radius: 7px;
    padding: 10px;
    margin-top: 10px;
    display: flex;
    gap: 10px;
    
}
.MarkAttendanceform-radio-card{
   
    display: flex;
    gap: 10px;
}
.Biometric{
    display: block;
}
#Biometric_il{
    display: inline-block;
    text-decoration: underline;
    color: rgb(76, 139, 201);
    font-weight: 500;
    margin-left: 30px;
}

/* Approval Css */
.approval-con {
    position: relative;
}
.approval-con .ver-line {
    position: relative;
    left: 20px;
    height: 30px;
    color: red;
    z-index: 99999;
    border-left: 1px solid rgb(126, 126, 128);
}
