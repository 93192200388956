.AddStaff {
  background-color: #fff;
  width: 70%;
  padding: 25px;
  margin: 15px auto;
  border-radius: 10px;
}
/* .AddStaff */

.AddStaff-input {
  /* border: 1px solid red; */
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  font-size: 18px;
  align-items: center;
  margin-top: 20px;
  position: relative;
}

.AddStaff-input > span {
  font-weight: bold;
}
.AddStaff-input > label {
  /* border: 2px solid blue; */
  font-size: 16px;
  width: 200px;
  font-weight: 400;
}
.AddStaff-input > input,
textarea {
  font-size: 16px;
  font-weight: 300;
  padding: 5px;
  width: 500px;
  height: 38px;
  border: 1px solid rgb(197, 196, 196);
  outline: none;
  border-radius: 5px;
}
.AddStaff-input > select {
  font-size: 18px;
  padding: 5px;
  width: 500px;
  border: 1px solid rgb(113, 113, 113);
  outline: none;
  border-radius: 5px;
}
#dropdownNumber {
  width: 80px;
  color: rgba(128, 128, 128, 0.855);
}
#inputNumber {
  width: 400px;
}
.from_btn {
  /* display: flex;
  justify-content: center; */
  width: 95%;
}
.from_btn > button {
  padding: 10px 20px;
  font-size: 20px;
  font-weight: 500;
  border: none;
  background-color: rgb(58, 164, 239);
  color: #fff;
  border-radius: 7px;
}
.submit-btn > button {
  /* width: 200px;
  height: 40px; */
  margin-top: 15px;
  padding: 8px 50px;
  border: none;
  font-size: 20px;
  font-weight: 500;
  background-color: rgb(58, 164, 239);
  color: #fff;
  border-radius: 7px;
}

#add_staff-erMsg {
  font-size: 18px;
  color: red;
  position: absolute;
  bottom: -24px;
  left: 250px;
}
.AddStaff-heading {
  font-size: 20px;
}
.back {
  display: flex;
  background-color: #fff;
  gap: 20px;
  align-items: center;
  padding: 10px;
  /* width: 100%; */
  /* height: 50px; */
  position: sticky;
  top: 1px;
  z-index: 1;
}
.back > a {
  font-size: 20px;
  color: gray;
  margin-top: -5px;
}
.back > p {
  font-size: 18px;
}

@media (max-width: 600px) {
    /* #add_staff-erMsg {
        background-color: lightblue;
        font-size: 14px;from_btn
    } */
     .AddStaff{
        width: 95%;
        margin: 10px auto
     }
}
