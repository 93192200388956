.Register {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: rgba(151, 214, 245, 0.7);
}

.register-container {
  width: 60%;
  display: flex;
  border-radius: 10px;
  max-height: 400px;
  overflow: hidden;
  /* box-shadow: var(--box-shadow); */
  /* border: 1px solid red; */
}

.register_left {
  flex: 2;
}

.register_left > img {
  width: 100%;
  height: 400px;
}

.register_right {
  flex: 1;
  background-color: #fff;
  display: flex;
  padding: 10px;
}

.register_right > form {
  padding: 7px;
  width: 100%;
  margin: auto;
}

.register-input-dev {
  margin-top: 19px;
  border: 1px solid rgb(142, 142, 142);
  display: flex;
  position: relative;
  border-radius: 5px;
  align-items: center;
}

#heading {
  padding: 10px;
}

.register-input-dev > input {
  padding: 10px;
  outline: none;
  border: none;
  width: 100%;
  border-radius: 5px;
}

#erMsg {
  display: inline-block;
  font-size: 12px;
  color: red;
  position: absolute;
  top: 100%;
  left: 2%;
}

.register-input-dev > button {
  width: 100%;
  color: white;
  padding: 5px;
  border: none;
  background-color: blue;
  border-radius: 5px;
}

#Register-label {
  display: inline-block;
  font-size: 12px;
  margin-top: 10px;
  text-align: center;
}

#Register-label > samp {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}
