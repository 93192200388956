.userInfo {
  height: 91.1vh;
  overflow: hidden;
}

.userInfo-heading {
  background-color: #fff;
  display: flex;
  width: 100%;
  align-items: center;
  gap: 10px;
  position: sticky;
  top: 1px;
  z-index: 2;
  /* border-top: 1px solid rgb(152, 152, 152); */
}
.userInfo-heading > a {
}
.userInfo-heading > P {
  font-size: 22px;
  padding: 20px;
}

.userInfo-Body {
  display: flex;
}
.userInfo-Body-left {
  width: 300px;
  overflow: auto;
  height: 81.2vh;
}
.userInfo-Body-right {
  flex: 1;
  height: 83vh;
  overflow: auto;
  /* border: 1px solid red; */
}
.user-info-list {
  background-color: #fff;
  padding: 15px 10px;
  margin-top: 5px;
}
.user-info-list > p {
  font-size: 15px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.PersonalDetails-heading {
  background-color: #fff;
  padding: 15px;
  font-weight: 500;
  font-size: 18px;
  border-top: 4px solid rgb(240, 237, 237);
  border-radius: 7px;
  position: sticky;
  top: 1px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
}
.PersonalDetails-heading > button {
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    color: #3c3d3f;
    background-color: #ffffff;
    border: 1px solid #d5d9d9;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 7px;
    width: fit-content;
    min-width: 160px;
    text-decoration: none;
}
.PersonalDetails-body {
  padding-bottom: 80px;
}

.BasicDetails {
  padding: 10px;
}

.BasicDetails_heading {
  padding: 15px 0;
  margin-left: -15px;
  background-color: #fff;
}

.BasicDetails_heading > p {
  font-size: 18px;
  padding: 10px 20px;
}

.BasicDetails-form {
  background-color: #fff;
  min-width: fit-content;
  margin: auto;
  padding: 20px 5px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}

.BasicDetails-form > form {
  /* border: 1px solid red;  */
}

.BasicDetails-input {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  font-size: 16px;
  align-items: center;
  margin-top: 20px;
  position: relative;
}

.BasicDetails-input > span {
  font-weight: bold;
}

.BasicDetails-input > label {
  width: 200px;
  font-weight: 400;
}

.BasicDetails-input > input,
textarea {
  font-size: 16px;
  font-weight: 300;
  padding: 5px;
  width: 500px;
  height: 38px;
  border: 1px solid rgb(207, 205, 205);
  outline: none;
  border-radius: 5px;
}

.BasicDetails-input > select {
  font-size: 16px;
  font-weight: 300;
  padding: 5px;
  width: 500px;
  height: 38px;
  color: rgb(70, 68, 68);;
  background-color: #fff;
  border: 1px solid rgb(207, 205, 205);
  outline: none;
  border-radius: 5px;
}

#dropdownNumber {
  width: 80px;
  color: rgba(128, 128, 128, 0.855);
}

#inputNumber {
  width: 400px;
}

.from_btn {
  display: flex;
  justify-content: flex-end;
  width: 95%;
}

.from_btn > button {
  padding: 10px 20px;
  font-size: 20px;
  font-weight: 500;
  border: none;
  background-color: rgb(58, 164, 239);
  color: #fff;
  border-radius: 7px;
}

#add_staff-erMsg {
  font-size: 18px;
  color: red;
  position: absolute;
  bottom: -24px;
  left: 250px;
}


table tbody  tr:hover {
  background-color: #ffffff; /* Assuming you want the hover color to be white */
}