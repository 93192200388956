.EmployeesManagers-select{
   
}
.EmployeesManagers-table-select{
    border: 1px solid rgb(199, 199, 199);
    width: 200px;
    padding: 10px;
    text-align: center;
}
.Employees-Managers-search-input{
    display: flex;
    border: 1px solid rgb(184, 184, 184);
    width: fit-content;
    align-items: center;
    padding-left: 10px;

}
.Employees-Managers-search-input>input {
    padding: 10px;
    width: 400px;
    border: none;
    outline: none;
}
.Employees-Managers-search-input>button{
    padding: 10px;
    background-color: rgb(63, 124, 237);
    color: #fff;
    font-size: 19px;
    border: none;
}