.AdminPanelLayOut{
    height: 100vh;
    overflow: auto;
    background-color: #F2F0F0;
   
}
.AdminPanelLayOut-body{
    display: flex;
}
/* .AdminPanelLayOut-body-left{
    flex: 1.2;
    border: 1px  solid red;
} */
.AdminPanelLayOut-body-right{
    height:91vh ;
    overflow: auto;
    width: 100%;
    
  
    
}