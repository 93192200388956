.LadingPageCardFive{
    min-height: 91.2vh;
    display: flex;
    flex-direction: column;
   justify-content: space-between;
   
}
.LadingPageCardFive-card-top{
background-color: #019EFF;
text-align: center;
color: #ffff;
min-height: 40vh;
}
.LadingPageCardFive-card-top >h1{
 margin-top: 20px;
 font-size: 32px;
}
.LadingPageCardFive-card-top>p {
    margin-top: 10px;
    font-size: 20px;
    font-weight: 300;
    
}
.LadingPageCardFive-input-tag{
    display: flex;
    flex-wrap: wrap;
    /* border: 1px solid red; */
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 70px;
}
.LadingPageCardFive-input-tag>input{
    border: none;
    outline: none;
    padding: 10px;
    font-size: 18px;
    border-radius: 10px;
    width: 250px;
}
.LadingPageCardFive-input-tag>button{
    border: none;
    background-color: #115D8C;
    padding: 10px 30px;
    color: #fff;
    font-size: 20px;
    border-radius: 5px;
}

.LadingPageCardFive-card-bottom{
    min-height: 40vh;
   
    background: linear-gradient(to bottom, #145FDE, #3261AE);
   position: relative;
}

.bottom-left{
    position: absolute;
        top: -20%;
        left: 3%;
}
.bottom-left>img{
    max-width: 344px;
    min-width: 50%;
   
    
}
.bottom-Right{
    color: #fff;
   
   

}
.bottom-Right>h1{
    margin: 20px 500px;
    width: 50%;
    font-size: 45px;
}
.bottom-Right-button{
    margin: 40px 500px;
    width: 40%;
    display: flex;
    justify-content:center;
    /* border: 1px solid red; */
    gap: 40px;
}
.bottom-Right-button>button{
    font-size: 17px;
    padding: 15px 30px;
    color: blue;
    background-color: #fff;
    border: none;
    border-radius: 7px;
}
