.Dashboard {
  padding-bottom: 100px;
}

.Dashboard-head {
  min-height: 200px;
  background-color: black;
  position: relative;
  /* border: 1px solid red; */
}
.Dashboard-info {
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  padding: 20px;
}
.Dashboard-card {
  top: 60%;
  position: absolute;
  width: 100%;
}
.Dashboard-card-items {
  display: flex;
  gap: 20px;
  width: 100%;
  justify-content: space-evenly;
  margin: auto;
  /* flex-wrap: wrap; */
}
.Dashboard-bar-chart {
  margin-top: 150px;
  width: 90%;
  margin: 120px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 15px;
}

.Dashboard-wishes-container {
  margin-top: 10px;
  display: flex;
  width: 90%;
  margin: auto;
  gap: 30px;
}

.record-div {
  position: relative;
  
}

.setting-notification {
  position: relative;
  top: -35px;
  left: 50%;
  transform: translate(-50%);
  padding: 5px 10px;
  background-color: rgba(211, 211, 211, 0.233);
  color: #fff;
  width: 50%;
  margin-top: 10px;
  border-radius: 7px;
}
.record-div{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.setting-notification a {
  text-decoration: none;
  color: #0a0a0a;
  animation: blink 1s infinite;
  transition: all 2s;
  background-color: #fff;
  padding: 5px;
  border-radius: 7px;
  text-decoration: none;
}

@keyframes blink {
  50% {
    color: rgb(251, 255, 192);
    background-color: red;
  }
}
