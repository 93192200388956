@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Outfit;
  text-decoration: none;
}

/* * {
  margin: 0;
  padding: 0;
  font-family: 'Segoe UI';
}  */

a {
  text-decoration: none;
  color: black;
  text-decoration: none;
}
::-webkit-scrollbar {
  display: none;
}

.MuiDateCalendar-root.css-9zab1y-MuiDateCalendar-root {
  max-height: 260px;
}

/* nav bar */

.nav-bar .SubMenu {
  position: absolute;
  display: none;
  background-color: #fff;
  padding: 10px 0px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 7px;
  z-index: 9999;
}
.nav-bar .SubMenu li {
  list-style: none;
  color: black;
  font-size: 14px;
  font-weight: 400;
  margin: 5px 15px;
}
.nav-bar .SubMenu li a {
  text-decoration: none;
  color: #000;
}
.nav-bar .SubMenu li a:hover {
  color: gray;
}
.nav-bar:hover label ~ .SubMenu {
  display: block;
}

.border-bottom {
  border-bottom: 1px solid lightgray;
}
.error {
  color: #dc4345;
}

#width-70-px {
  width: 70px;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-justify-center {
  display: flex;
  justify-content: center;
  gap: 10px;
}
.align-items-center {
  align-items: center;
}
.flex-align-center {
  display: flex;
  align-items: center;
  gap: 10px;
}
.nowrap {
  white-space: nowrap;
}

.left-right-padding {
  padding: 0 60px;
}

.radio-padding {
  padding: 8px 30px;
  border: 1px solid lightgray;
  border-radius: 7px;
}

.body-container {
  padding: 0px 8px;
}

.page-top-bar-1 {
  padding: 15px;
  position: sticky;
  top: 1px;
  background-color: #fff;
  display: flex;
  justify-content: start;
  align-items: center;
  z-index: 1;
}

.page-top-bar {
  padding: 15px;
  position: sticky;
  top: 1px;
  background-color: #fff;
  border-top: 4px solid rgb(240, 237, 237);
  border-radius: 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 3;
}
.add-icon {
  color: #3498db;
  font-size: 24px;
  font-weight: bold;
}
.remove-icon {
  color: #e74c3c;
  font-size: 24px;
  font-weight: bold;
}
.add-text {
  color: #3498db;
  /* font-size : 24px; */
  /* font-weight: bold; */
}

.sub-title h1 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgb(209, 207, 207);
}

.info-icon {
  color: rgb(38, 181, 247);
  font-size: 18px;
  margin-top: -4px;
  margin-left: 4px;
  cursor: pointer;
}

.title-flex {
  display: flex;
  align-items: center;
  justify-content: start;
}

.page-top-bar > h1 {
  font-size: 19px;
  font-weight: 500;
}

.page-top-bar > h1 .icon {
  font-size: 30px;
  font-weight: 500;
}

.text-bold {
  font-weight: 500;
}

.color-gray {
  color: gray;
}

.page-body {
  position: relative;
  top: 8px;
  padding: 20px;
  background-color: #fff;
  border-radius: 7px;
  min-height: 100%;
}

.body-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding-bottom: 10px;
  border-bottom: 1px solid lightgray; */
}

.body-header .left-header {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 10px;
}

.body-header .left-header img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.body-header .right-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.body-subheader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: scroll;
}

.body-subheader > .flex-column {
  width: 200px;
  border-radius: 7px;
  padding: 4px 10px;
}
.body-subheader > .flex-column.present {
  border-left: 2px solid #7cd4a2;
  background-color: #eef7f2;
}
.body-subheader > .flex-column.present span:first-child {
  font-size: 12px;
  color: gray;
}

.body-subheader > .flex-column.absent {
  border-left: 2px solid #ec7063;
  background-color: #faeae9;
}
.body-subheader > .flex-column.absent span:first-child {
  font-size: 12px;
  color: gray;
}

.body-subheader > .flex-column.half-day {
  border-left: 2px solid #f8c471;
  background-color: #fef5e7;
}
.body-subheader > .flex-column.half-day span:first-child {
  font-size: 12px;
  color: gray;
}

.body-subheader > .flex-column.paid-leave {
  border-left: 2px solid #af7ac5;
  background-color: #f5eef8;
}
.body-subheader > .flex-column.paid-leave span:first-child {
  font-size: 12px;
  color: gray;
}

.body-subheader > .flex-column.week-off {
  border-left: 2px solid #abb2b9;
  background-color: #f8f9f9;
}
.body-subheader > .flex-column.week-off span:first-child {
  font-size: 12px;
  color: gray;
}

.body-content .day-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.day-inner-container {
  padding: 5px;
}
.body-content {
  display: flex;
}
.body-content .day-container .day-inner-container {
  height: 180px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.body-content .day-container .day-names {
  border: 1px solid lightgray;
  border-right: none;
  width: 100%;
  text-align: center;
  padding: 5px 0;
}

.body-content .day-container:last-child .day-names {
  border-right: 1px solid lightgray;
}

.body-content .day-container .date {
  background-color: lightgray;
  border-radius: 5px;
  text-align: center;
  padding: 5px 0;
}

.mini-head h1 {
  font-size: 16px;
  border-bottom: 1px solid lightgray;
  padding-bottom: 10px;
}

.empty-content {
  height: 82dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.empty-content > img {
  width: 80px;
  height: 80px;
  margin: 0 auto;
}

.empty-content > p {
  position: relative;
  top: 10px;
}

/* ---------------------------------------------------------- */
::-webkit-inner-spin-button {
  display: none;
}
::-webkit-calendar-picker-indicator {
  background-color: white;
}
input[type="date"] {
  /* font-size:25px; */
}
::-webkit-datetime-edit-text {
  color: #555555;
}
::-webkit-datetime-edit-month-field {
  color: #555555;
}
::-webkit-datetime-edit-day-field {
  color: #555555;
}
::-webkit-datetime-edit-year-field {
  color: #555555;
}
::-webkit-calendar-picker-indicator {
  background-image: url(http://icons.iconarchive.com/icons/dakirby309/simply-styled/256/Calendar-icon.png);
  background-position: center;
  background-size: 20px 20px;
  background-repeat: no-repeat;
  color: rgba(204, 204, 204, 0);
}
/* input[type=date]::-webkit-calendar-picker-indicator:active {
  color: rgb(255, 60, 0);
} */
/* ::-webkit-clear-button { } */

.remove .css-1nrlq1o-MuiFormControl-root .css-13cymwt-control {
  border: none;
  cursor: pointer;
}
.remove .css-1u9des2-indicatorSeparator,
.remove .css-1xc3v61-indicatorContainer .css-tj5bde-Svg {
  display: none;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  transform: translateY(50%);
}

/* the code below is for the dropdown menu */
.wrapper_dropdown {
  position: relative;
  display: flex;
  justify-content: space-between;
  /* min-width: 331.5px; */
  width: 100%;
  /* max-width: 483px; */
  min-height: 44px;
  border: 1px solid lightgray;
  border-radius: 7px;
  background: #fff;
  text-align: left;
  color: #000;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: Arial;
}

.scrollable-menu {
  height: auto;
  max-height: 200px;
  overflow-x: hidden;
}

.arrow {
  margin-left: 10px;
  margin-right: 10px;
  float: right;
  rotate: 180deg;
}

.selected-display {
  margin-left: 20px;
  margin-top: 11px;
}

.wrapper_dropdown svg {
  transition: all 0.3s;
  margin-top: 11px;
}

.wrapper_dropdown::before {
  position: absolute;
  /*   top: 50%; */
  right: 16px;

  margin-top: -2px;

  border-width: 6px 6px 0 6px;
  border-style: solid;
  border-color: #fff transparent;
}

.rotated {
  transform: rotate(-180deg);
}

.wrapper_dropdown .dropdown {
  transition: 0.3s;

  position: absolute;
  top: 120%;
  right: 0;
  left: 0;

  margin: 0;
  padding: 0;

  list-style: none;

  z-index: 99;

  /* border-radius: 7px; */
  box-shadow: inherit;
  background: #fff;
  color: #000;

  -webkit-transform-origin: top;
  -moz-transform-origin: top;
  -ms-transform-origin: top;
  transform-origin: top;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  opacity: 0;
  visibility: hidden;
  border: 1px solid lightgray;
  border-radius: 7px;
}

.wrapper_dropdown .dropdown li {
  padding: 0 15px;
  line-height: 45px;
  overflow: hidden;
  border: none;
}

.wrapper_dropdown .dropdown li:last-child {
  border-bottom: none;
}

.dropdown {
  padding: 0.5rem !important;
}

.wrapper_dropdown .dropdown li:hover {
  background-color: rgb(248, 246, 246);
  border-radius: 10px;
}

.wrapper_dropdown.active .dropdown {
  opacity: 1;
  visibility: visible;
  border-radius: 7px;
}

.modal-header .close {
  display: none;
}

.table-link {
  color: blue;
  text-decoration: none;
}

.table-link:hover {
  background-color: transparent;
  color: #3498db;
}

/* .start-time-picker[data-placement^='bottom'] {
  transform: translate3d(0px, 10px, 0px) !important;
}

.end-time-picker[data-placement^='bottom'] {
  transform: translate3d(0px, 10px, 0px) !important;
} */

.circular-timepicker, .circular-timepicker-1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  background-color: #fff;
  width: 210px;
  border-radius: 7px;
  margin-top: 5px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 10px;
  position: absolute;
  z-index: 1;
}
.am-pm-container {
  display: flex;
  align-items: center;
  gap: 10px;
}
.am-pm-container .am,
.am-pm-container .pm {
  padding: 10px;
  box-shadow: rgba(28, 28, 29, 0.2) 0px 8px 24px;
  border-radius: 5px;
  cursor: pointer;
}

.time-taking-done {
  position: relative;
  margin-top: 10px;
  cursor: pointer;
}

.circular-timepicker-1{
  top: -10px;
}