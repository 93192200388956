.EmployeeManagement {
    /* border: 1px solid red; */
    /* min-height: 91.1vh; */
  }
  .EmployeeManagement-card-one {
    /* border: 1px solid red; */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 80px;
    margin-top: 20px;
    width: fit-content;
    margin: 40px auto;
  }
  .details-box {
    width: 300px;
    height: 107px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    text-align: right;
    align-items: center;
    background-color: #fff;
  }
  .details-box-icon {
    margin: auto;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  .te-icon {
    border: 10px solid #ffc625;
  }
  .me-icon {
    border: 10px solid #67c5ff;
  }
  .ne-icon {
    border: 10px solid #9667fb;
  }
  .fe-icon {
    border: 10px solid #ff8f8f;
  }
  
  .details-info {
    padding: 20px;
  }
  .details-info > time {
    font-weight: 700;
    font-size: 26px;
  }
  .details-info > p {
    font-size: 17px;
    font-weight: 500;
  }
  .EmployeeManagement-card-two {
    width: 93%;
    padding: 20px;
    margin: auto;
    display: flex;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
  }
  
  .EmployeeManagement-card-two > a {
    padding: 15px 20px;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    color: #3c3d3f;
    background-color: #ffffff;
    border: 1px solid #d5d9d9;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 7px;
    width: fit-content;
    min-width: 160px;
    text-decoration: none;
  }
  
  
  .categories-table {
    border-radius: 7px;
    overflow: hidden;
  }
  
  .categories-table .table {
    padding: 20px;
    border-radius: 7px;
  }
  
  .categories-table .table table thead tr th {
    font-size: 16px;
    font-weight: 400;
  }
  .categories-table .table table tbody tr td {
    font-size: 16px;
    font-weight: 200;
  }
  /* EmployeeManagementTab */
  
  .EmployeeManagementTab {
    display: flex;
    background-color: #ffffff;
    min-width: fit-content;
    max-width: fit-content;
    gap: 50px;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 7px;
  }
  .EmployeeManagementTab > p {
    font-size: 16px;
    font-weight: 500;
    padding: 10px 5px;
    min-width: fit-content;
  }
  .EmployeeManagement-card-three {
    width: 90%;
    margin: 20px auto;
    /* max-height: 60vh;
    overflow: auto; */
  }
  
  a {
    text-decoration: none;
  }
  