.footer-tag{
    min-height: 91.2vh;
    /* border: 2px solid red; */
}




.gradient-container {
    /* height: 40vh; */
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    /* background: linear-gradient(to bottom left, #019EFF, #019EFF 85%, #081B2E 15%, #081B2E); */
    background: #019EFF;
    position: relative;
}

#svg {
    position: absolute;
    bottom: 0;
    left: 0;
}

.newsimage img {
    height: 300px;
    margin-left: 220px;
    margin-top: 25px;
}

.emailadd {
    margin-top: 30px;
}


.lastletter {
    display: flex;
    flex-direction: row;
    position: relative;
    margin-top: 30px;
    
}

.lastmail {
    height: 55px;
    width: 442px;
    border: 1px solid white;
    font-size: 20px;
    outline: none;
    border-radius: 20px 0px 0px 20px;
}

.lastbutton {
    height: 55px;
    background-color: #137DBE;
    border-radius: 0px 20px 20px 0px;
    position: absolute;
    left: 440px;
    cursor: pointer;
    border: 1px solid #137DBE;
    border: none;
}

.lastbutton img {
    height: 60px;
    transform: rotate(270deg);
    
}


.footer{
    
    width: 100%;
    min-height: 55vh;
    background: #081B2E;
    display: flex;
    justify-content: space-around;
    color: white;
     
} 

.aboutus {
    max-width: 40%;
    margin-top: 20px;
   
}

.logo-Copyright {
    margin-top: 15%;
    font-size: 23px;
}

.links {
    max-width: 30%;
    margin-top: 20px;
   
}

.link {
    display: flex;
    flex-direction: column;
    
}

.link a {
    color: white;
    text-decoration: none;
    padding: 10px;
}

.social {
    max-width: 30%;
    margin-top: 20px;
}

.social img {
    height: 100px;
    cursor: pointer;
}

 @media (max-width:768px) {
    .footer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        padding: 25px;
    }

    .aboutus {
        max-width: 80%;
    }

    .links {
        max-width: 80%;
    }

    .social {
        max-width: 80%;
    }
} 



