.user_management-table{
    width: 90%;
    margin:20px auto;
    background-color: #fff;
    padding: 10px;
}
.user_management-table-search{
   
    display: flex;
    align-items: center;
    gap: 10px;
    padding-bottom: 20px;

}
.user_management-table-search>input{
    padding: 10px 5px;
    width: 300px;
    outline: none;
}

.user_management-table-search>button{
    border: none;
        background-color: rgb(126, 126, 126);
        color: #fff;
        padding: 10px 20px;
        font-size: 17px;
        border-radius: 5px;
}   
.UserManagementForm>form{
   width: 100%;
    
}
.UserManagement-input{
    
    display: flex;
    gap: 2px;
}
.UserManagement-input>label{
    width:150px;

   
}
.UserManagement-input>input{
   
}