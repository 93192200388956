.DocumentType {
  background-color: #fff;
}
.DocumentType-Head {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  font-size: 20px;
  font-weight: 500;
  align-items: center;
}
.DocumentTyp-table {
  width: 95%;
  margin: auto;
}
