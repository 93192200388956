.LadingPageCardOne{
    min-height: 91.2vh;
    display: flex;
    background-color: #019EFF;
    /* border: 1px solid red; */
   
}
.LadingPageCardOne-left{
  flex: 50%;
  color: #fff;
  display: flex;
  /* align-items: center; */
  justify-content: center;

  
 
}
.LadingPageCardOne-left-container {
    width: 70%;
    height: 50vh;
    margin-top: 200px;
    margin-left: 20px;
    
}
.LadingPageCardOne-left-container p{
    font-size: 30px;
    font-weight: 300;
    
    
   
}
.LadingPageCardOne-left-container h1 {
    font-size: 57px;
   
   
}
.LadingPageCardOne-left-container >.label {
    font-size: 22px;
     width: 600px;
     padding: 10px;
     /* border: 1px solid red; */
     
}

.LadingPageCardOne-buttons{
    display: flex;
    gap: 20px;
    margin-top: 80px;
}
.LadingPageCardOne-buttons button{
    background-color: #1575B5;
     color: #FFFFFF;
    width: 250px;
     font-size: 25px;
     font-weight: 500;
     border: none;
     padding: 15px ;
     border-radius: 10px;
     

     
}

.LadingPageCardOne-right {
    flex: 30%;
    /* border: 1px solid green; */
    display: flex;
    align-items: center;
   
}
.LadingPageCardOne-right img{
   
    width: 80%;
}