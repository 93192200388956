.CompanyReports-body{

}

.CompanyReportsForm{
   width: 90%;
   margin: 20px auto;
   background-color: #fff;
   padding: 20px;
}
.CompanyReportsForm>form {
   display: flex;
   /* align-items: center; */
   gap: 50px;
   padding: 20px;
}

.CompanyReports-input{
   width: 15%;
}
.CompanyReports-input>label {
    display: block;
    
}
.CompanyReports-input>select{
    width: 100%;
    padding:10px;
    margin-top: 5px;
    border: 1px solid rgb(179, 179, 179);
    border-radius: 7px;
    background-color: #fff;
}
.CompanyReports-input>input {
    width: 100%;
    padding: 10px 20px;
    margin-top: 5px;
    border: 1px solid rgb(179, 179, 179);
    border-radius: 7px;
}
.CompanyReports-input>label{
    font-weight:500;
    font-size: 17px;
    color: grey;
}
.CompanyReports-btn>button{
   background-color: rgb(58, 139, 232);
   padding: 10px;
   color: #fff;
   border: none;
   border-radius: 5px;
   margin-left: 20px;
   font-size: 18px;
}
.CompanyReports-btn>p{
  margin:10px 20px ;
  font-weight: 400;
  color: rgb(95, 95, 95);
}

.ReportsTable-table{
   
}
.ReportsTable-body{
    width: 90%;
    margin: auto;
    padding: 20px;
    background-color: #fff;
}
.ReportsTable-head{
    
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0px;
}
.ReportsTable-head>p{
    font-size: 20px;
}

.ReportsTable-head>button{
    background-color: rgb(58, 139, 232);
        padding: 10px;
        color: #fff;
        border: none;
        border-radius: 5px;
        margin-left: 20px;
        font-size: 18px;
}

#ReportType-checkbox{
    margin-top: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
}
#ReportType-checkbox>input{
    width: 20px;
    height: 20px;
}
