.pending-action {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}

.pending-action > ul {
    margin: 0;
    margin-top: -10px;
    padding: 0;
}

.pending-action > ul li {
    display: block;
    list-style: none;
    padding: 10px 0;
    border-bottom: 1px solid lightgray;
}

.pending-action > ul li:first-child {
    padding-top: 0;
}

.pending-action > ul li a{
    display: block;
    text-decoration: none;
    font-size: 15px;
    color: #000;
}

.approve-leaves {
    font-size: 15px;
    margin-top: -10px;
    padding-bottom: 10px;
    border-bottom: 1px solid lightgray;
}

.getting-started .header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(236, 235, 235);
    padding: 8px 5px;
    border-bottom: 1px solid lightgray;
}

.getting-started .header h1 {
    font-size: 16px;
}

.getting-started .header .icon {
    font-size: 20px;
}

.getting-started .body {
    font-size: 20px;
}

.getting-started > .body ul {
    margin: 0;
    padding: 0;
}
.getting-started > .body ul li{
    list-style: none;
    border-bottom: 1px solid lightgray;
    padding: 10px 0;
}
.getting-started > .body ul li a{
    display: block;
    text-decoration: none;
    font-size: 13px;
    color: #000;
}

.card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.card-header h1{
    font-size: 16px;
}

.card-text ul, .statutory-container ul{
    margin: 0;
    padding: 0;
}

.card-text ul li , .statutory-container ul li{
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    padding: 10px 0;
    border-bottom: 1px solid lightgray;
}

.card-text ul li:last-child, .statutory-container ul li:last-child {
    border: none;
}

.card-text ul li .left , .statutory-container ul li .left{
    font-weight: 400;
}

.card-text ul li .right , .statutory-container ul li .right{
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* width: 200px */
}
.card-text ul li .right span , .statutory-container ul li .right span{
    width: 100px;
    text-align: center;
}

.card-text ul li .right span:first-child, .statutory-container ul li .right span:first-child{
    border-right: 2px solid lightgray;
}

.card-text ul li .right span:last-child, .statutory-container ul li .right span:last-child{
   text-align: right;
}

.acc-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.acc-header .right span{
    padding: 0 20px;
}

.acc-header .right span:first-child{
    border-right: 2px solid rgb(158, 160, 160);
}

.acc-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: gray;
    font-size: 15px;
}

.accordion-header .accordion-button:not(.collapsed){
    background:#fff;
    color: #000;
    outline: none;
    border-radius: 0;
}
.accordion-header{
    border-radius: 20px;
}
/* .card-header:not(.collapsed) {
    background:#ee6666;
} */

.statutory-container{
    border: 1px solid lightgray;
    margin-top: 25px;
    border-radius: 7px;
}
.statutory-container h1{
    padding: 10px 20px;
    font-size: 16px;
}